import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAqkwh6H-6F6OM56YuWZwjE1kIIX37T9mE",
  authDomain: "ctrlzcomtr01.firebaseapp.com",
  projectId: "ctrlzcomtr01",
  storageBucket: "ctrlzcomtr01.appspot.com",
  messagingSenderId: "1024170388638",
  appId: "1:1024170388638:web:85a6440be2a9eabf345f8f",
  measurementId: "G-SLXK2172XY"
};


// eslint-disable-next-line
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const app = initializeApp(firebaseConfig);
const authentication = getAuth(app);
const messaging = getMessaging(app)

export { app, authentication, messaging };
